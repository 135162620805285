import { gql, useQuery } from '@apollo/client';

import PostsFeed from "components/templates/PostsFeed";

const GET_POSTS = gql`
  query GetPosts {
    posts {
      content
      date
      id
      img
      summary
      title
    }
  }
`;

export const Home = () => {
  const { loading, error, data } = useQuery(GET_POSTS);

  return (<PostsFeed loading={loading} error={error && error.message} posts={data && data.posts as Post[]} />)
};
