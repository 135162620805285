import style from "./style.module.scss";

type DateProps = {
  date: String;
  className?: String;
};

export const Date = (props: DateProps) => (<div className={`${style.date} ${props.className}`}>{props.date}</div>);

export default Date;
