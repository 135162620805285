import style from "./style.module.scss";

import Logo from "components/atoms/Logo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

type NavbarProps = {
  className?: string;
};

export const Navbar = (props: NavbarProps) => (
<section className={style.navbar}>
  <div className={`${style.title} ${props.className}`}>
    <div className={style.logoContainer}><Link to="/"><Logo className={style.logo} /></Link></div>
    <div className={style.contact}>
      <span><FontAwesomeIcon icon={faEnvelope} /> community@kefaise.com</span>
    </div>
  </div>
  <div className={style.stripe} />
</section>
);

export default Navbar;
