import Date from "components/atoms/Date";
import PostSummary from "components/atoms/PostSummary";
import PostTitle from "components/atoms/PostTitle";
import { Link } from "react-router-dom";
import style from "./style.module.scss";

import moment from "moment";

type PostSummaryTileProps = {
  post: Post;
  className?: String;
};

export const PostSummaryTile = (props: PostSummaryTileProps) => (<article className={`${style.postSummary} ${props.className}`}>
  <header>
  <Link to={`/Post/${props.post.id}`}><img src={props.post.img} alt={props.post.title} /></Link>
    <Date date={moment(props.post.date).format("DD MMM YYYY")} />
    <PostTitle><Link to={`/Post/${props.post.id}`}>{props.post.title}</Link></PostTitle>
  </header>
  <PostSummary>{props.post.summary}</PostSummary>
  <footer>
    <Link to={`/Post/${props.post.id}`}>Read more</Link>
  </footer>
</article>);

export default PostSummaryTile;
