import React from "react";
import ReactDOM from "react-dom/client";

import {
  BrowserRouter, Routes, Route,
} from "react-router-dom";

import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import config from "./config";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Home } from "pages/Home";
import { Post } from "pages/Post";

const apolloClient = new ApolloClient({
  uri: config("GRAPHQL_URL"),
  headers: {
    "Authorization": "ANY",
  },
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Post/:postId" element={<Post />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
