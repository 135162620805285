import style from "./style.module.scss";

type LogoProps = {
  className?: string;
};

export const Logo = (props: LogoProps) => {
  return (
    <div className={`${style.logo} ${props.className}`} >Kefblog</div>
  );
};

export default Logo;
