import {ReactNode} from "react";
import style from "./style.module.scss"

type PostTitleProps = {
  children: ReactNode;
};

export const PostTitle = (props: PostTitleProps) => <h1 className={style.postTitle}>{props.children}</h1>;

export default PostTitle;
