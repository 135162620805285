import configDev from "./config.development";
import configProd from "./config.production";

let config: (key: string) => any;
if (process.env.NODE_ENV === "development") {
  config = configDev;
} else {
  config = configProd;
}

export default config;
