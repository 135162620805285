import _ from "lodash";

import Navbar from "components/organisms/Navbar";
import style from "./style.module.scss";
import PostSummaryTile from "components/organisms/PostSummaryTile";
import Footer from "components/organisms/Footer";

type PostsFeedProps = {
  posts?: Post[];
  loading: Boolean,
  error?: String,
};

export const PostsFeed = (props: PostsFeedProps) => {
  return (
    <>
      <Navbar />
        <section className={style.main}>
          {
            (props.loading && !props.error && (<p>Loading...</p>))
            || (!props.loading && props.error && (<p>Error: {props.error}</p>))
            || (!props.loading && !props.error && props.posts && (
              _.map(props.posts, (post: Post) => (<PostSummaryTile post={post} className={style.postSummaryTile} />) )
            ))
          }
        </section>
      <Footer />
    </>
  );
}

export default PostsFeed;
