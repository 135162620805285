import { ReactNode } from "react";
import style from "./style.module.scss";

type PostSummaryProps = {
  children: ReactNode;
};

export const PostSummary = (props: PostSummaryProps) => <div className={style.postSummary}>{props.children}</div>;

export default PostSummary;
