import { gql, useQuery } from '@apollo/client';

import SinglePost from 'components/templates/SinglePost';
import { useParams } from 'react-router-dom';

const GET_POST = gql`
  query GetPost($postId: ID!) {
    post(id: $postId) {
      content
      date
      id
      img
      summary
      title
    }
  }
`;

export const Post = () => {
  const { postId } = useParams();
  const { loading, error, data } = useQuery(GET_POST, { variables: { postId } });

  return (<SinglePost post={data?.post as Post} loading={loading} error={error && error.message} />)
};
