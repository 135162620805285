import ReactMarkdown from "react-markdown";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark as highlightTheme } from "react-syntax-highlighter/dist/esm/styles/prism";

import moment from "moment";

import Date from "components/atoms/Date";
import PostTitle from "components/atoms/PostTitle";
import Navbar from "components/organisms/Navbar";
import style from "./style.module.scss";
import Footer from "components/organisms/Footer";

type SinglePostProps = {
  post?: Post;
  loading: Boolean,
  error?: String,
};

export const SinglePost = (props: SinglePostProps) => {
  return (
    <>
      <Navbar />
      <section className={style.main}>
      {
        (props.loading && !props.error && (<p>Loading...</p>))
        || (!props.loading && props.error && (<p>Error: {props.error}</p>))
        || (!props.loading && !props.error && props.post && (
        <article>
          <header>
            <img src={props.post.img} alt={props.post.title} />
            <Date date={moment(props.post.date).format("DD MMM YYYY")} />
            <PostTitle>{props.post.title}</PostTitle>
          </header>
          <main>
            <ReactMarkdown
              components={{
                code: ({node, inline, className, children, ...props} ) => {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? 
                    (<SyntaxHighlighter language={match[1]} PreTag="div" {...props} style={highlightTheme}>{String(children).replace(/\n$/, '')}</SyntaxHighlighter>)
                    : (<code className={className} {...props}>{children}</code>);
                }
              }}
            >{props.post.content}</ReactMarkdown>
          </main>
        </article>
        ))
      }
      </section>
      <Footer />
    </>
  );
}

export default SinglePost;
